<template>
    <div class="flexPage bind-wechat-pay">
        <div class="flexHead">
            <navbar :params="pageInfo">
                <template #right>
                    <span @click="save" class="nav-right-text">{{ $t('other.translate109') }}</span>
                </template>
            </navbar>
        </div>
        <van-cell-group :border="false">
            <van-cell :title="$t('other.translate108')">
                <template #default>
                    <van-uploader name="file" multiple :after-read="onAfterReadFront">
                        <div class="icon_id_face">
                            <template v-if="formData.qrCodeUrl">
                                <img :src="formData.qrCodeUrl" alt="">
                            </template>
                            <template v-else>
                                <img src="../../assets/img/updianji.png" alt="">
                            </template>
                        </div>
                    </van-uploader>
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate103')">
                <template #default>
                    <van-field placeholder="redmi_1" :border="false" input-align="right" disabled />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate100')">
                <template #default>
                    <van-field v-model="formData.wechat" :placeholder="$t('other.translate121')" :border="false" input-align="right" />
                </template>
            </van-cell>
            <van-cell :title="$t('other.translate104')">
                <template #default>
                    <van-field v-model="formData.jyPassword" :placeholder="$t('other.translate106')" type="password" :border="false"
                        input-align="right" />
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";

export default {
    data() {
        return {
            pageInfo: {
                customTitle: this.$t('other.translate122'),
                navbarStyle: "blue",
                backname: "payment",
            },
            formData: {
                qrCodeUrl: '',
                wechat: '',
                jyPassword: '',
                realName: 'redmi_1',
            },

        }
    },
    components: { navbar },
    methods: {
        onAfterReadFront(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            this.$http.post(this.host + '/uc/upload/local/image', formData).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.formData.qrCodeUrl = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        save() {
            let param = {};
            param["wechat"] = this.formData.wechat;
            param["jyPassword"] = this.formData.jyPassword;
            param["realName"] = this.formData.realName;
            param["qrCodeUrl"] = this.formData.qrCodeUrl;
            this.$http.post(this.host + "/uc/approve/bind/wechat", param).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.$toast(this.$t('other.translate110'));
                    this.$mts.goto('payment');
                } else {
                    this.$toast(resp.message);
                }
            });
        }
    }
}

</script>
<style>
.bind-wechat-pay {
    height: 100vh;
    background: #F8FAFD;
}

.bind-wechat-pay .flexHead .nav-right-text {
    color: #FFFFFF;
    font-size: 14px;
}

.bind-wechat-pay .icon_id_face {
    width: 10.1781vw;
    height: 10.1781vw;
}

.bind-wechat-pay .van-cell-group .van-cell {
    display: flex;
    align-items: center;
}

.bind-wechat-pay .van-cell-group .van-cell .van-cell__value .van-field {
    padding: unset;
}

.bind-wechat-pay .van-cell-group .van-cell .van-cell__value .van-field .van-field__value .van-field__body input {
    margin-bottom: unset;
    border: unset;
    padding: unset;
    height: unset;
}

.bind-wechat-pay .van-action-sheet__content {
    height: 76.3359vw;
}

.bind-wechat-pay .van-action-sheet__cancel:before {
    display: none;
}
</style>